import { Injectable } from '@angular/core'
import { Apollo } from 'apollo-angular'
import { Subject } from 'rxjs'
import { switchMap, takeUntil } from 'rxjs/operators'
import { BaseService } from 'services/base'
import { ErrorsMessagesService } from 'services/errors-messages/errors-messages.service'
import {
    APNewsroomImage,
    APNewsroomImageSearchResults,
    APNewsroomSearchOptions,
    APNewsroomSearchParams,
} from '../../interfaces/ap-newsroom'
import { networkQuery } from '../../tools/apollo'
import { apNewsroomImageQuery, apNewsroomSearchQuery } from './ap-newsroom.queries'

@Injectable({
    providedIn: 'root',
})
export class ApNewsroomService extends BaseService {
    public isSearchLoadingSubject = new Subject<boolean>()
    public isSearchActiveSubject = new Subject<boolean>()
    public searchResultsSubject = new Subject<APNewsroomImageSearchResults>()
    public isImageImportFinishedSubject = new Subject<true>()
    public imageImportedSubject = new Subject<APNewsroomImage>()

    private searchOptions?: APNewsroomSearchOptions
    private searchSubject = new Subject<{ params?: APNewsroomSearchParams; options?: APNewsroomSearchOptions }>()
    private imageImportSubject = new Subject<string>()

    constructor(protected apollo: Apollo, protected errorsMessagesService: ErrorsMessagesService) {
        super(apollo, errorsMessagesService)

        this.initSearchSubject()
        this.initImageImportSubject()
    }

    public search(search: { params?: APNewsroomSearchParams; options?: APNewsroomSearchOptions }): void {
        this.isSearchActiveSubject.next(true)
        this.searchSubject.next(search)
    }

    public searchPageChange(page: number): void {
        this.search({ options: { ...this.searchOptions, page } })
    }

    public exitSearch(): void {
        this.isSearchActiveSubject.next(false)
        this.searchResultsSubject.next()
        delete this.searchOptions
    }

    public importImage(id: string): void {
        this.imageImportSubject.next(id)
    }

    public getImageById(id: string) {
        return networkQuery<APNewsroomImage>(
            this.apollo,
            {
                query: apNewsroomImageQuery,
                variables: { id },
            },
            ['data', 'apNewsroomImage'],
        )
    }

    private initSearchSubject(): void {
        this.searchSubject
            .pipe(
                switchMap(search => {
                    this.isSearchLoadingSubject.next(true)
                    this.searchOptions = search.options

                    return networkQuery<APNewsroomImageSearchResults>(
                        this.apollo,
                        {
                            query: apNewsroomSearchQuery,
                            variables: {
                                params: search.params,
                                options: search.options,
                            },
                        },
                        ['data', 'apNewsroomImages'],
                    )
                }),
                takeUntil(this.ngUnsubscribe),
            )
            .subscribe({
                next: results => {
                    this.searchOptions = { ...this.searchOptions, id: results.id }
                    this.searchResultsSubject.next(results)
                    this.isSearchLoadingSubject.next(false)
                },
                error: () => {
                    this.isSearchLoadingSubject.next(false)
                },
            })
    }

    private initImageImportSubject(): void {
        this.imageImportSubject
            .pipe(
                switchMap(id => this.getImageById(id)),
                takeUntil(this.ngUnsubscribe),
            )
            .subscribe({
                next: image => {
                    this.imageImportedSubject.next(image)
                    this.isImageImportFinishedSubject.next(true)
                    this.isSearchActiveSubject.next(false)
                },
                error: () => {
                    this.isImageImportFinishedSubject.next(true)
                    this.isSearchActiveSubject.next(false)
                },
            })
    }
}
