import { gql } from '@apollo/client/core'
import { imageWithMetaFragment } from 'services/shared/image.fragment'
import { tagFragment } from 'services/tags/tags.fragment'

export const imageSearchQuery = gql`
    ${imageWithMetaFragment}
    ${tagFragment}

    query imageSearch($search: ImageSearchInput) {
        images(search: $search) {
            ...imageWithMetaInfo
            storyImageTypeId
            tags {
                ...tagInfo
            }
        }
    }
`
