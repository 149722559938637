export enum UserRole {
    JOURNALIST_AFN = 'JOURNALIST_AFN',
    JOURNALIST = 'JOURNALIST',
    DIGITAL_ADVANCED_USER = 'DIGITAL_ADVANCED_USER',
    EDITOR_OF_THE_DAY = 'EDITOR_OF_THE_DAY',
    EDITOR_OF_THE_DAY_AFN = 'EDITOR_OF_THE_DAY_AFN',
    CHIEF_EDITOR = 'CHIEF_EDITOR',
    DIGITAL_CHIEF_EDITOR = 'DIGITAL_CHIEF_EDITOR',
    DEVELOPER = 'DEVELOPER',
    ADMIN = 'ADMIN',
    BETA_TESTER = 'BETA_TESTER',
}

export enum UserRight {
    CREATE_EVENT = 'createEvent',
    UPDATE_EVENT = 'updateEvent',
    UPDATE_EVENT_EMBARGO = 'updateEventEmbargo',
    ACCESS_DIGITAL_PUBLISHING = 'accessDigitalPublishing',
    ACCESS_CONTRIBUTORS = 'accessContributors',
    EDIT_CONTRIBUTORS = 'editContributors',
    ACCESS_TRANSLATIONS = 'accessTranslations',
    EDIT_TRANSLATIONS = 'editTranslations',
    CREATE_TRANSLATIONS = 'createTranslations',
    DELETE_TRANSLATIONS = 'deleteTranslations',
    ACCESS_TAGS = 'accessTags',
    EDIT_TAGS = 'editTags',
    DELETE_TAGS = 'deleteTags',
    MODERATE_TAGS = 'moderateTags',
    GENERATE_TAGS_DESCRIPTIONS = 'generateTagsDescriptions',
    ACCESS_DASHBOARD_LATEST_TAGS = 'accessDashboardLatestTags',
    ACCESS_SCRIBBLELIVE_TIMELINE = 'accessScribbleLiveTimeline',
    ACCESS_SCRIBBLELIVE_FEEDS = 'accessScribbleLiveFeeds',
    ACCESS_WIDGETS_HOWTO = 'accessWidgetsHowTo',
    ACCESS_BCS = 'accessBcs',
    ACCESS_WIRES = 'accessWires',
    ACCESS_NATIVE_ADS_MANAGEMENT = 'accessNativeAdsManagement',
    ACCESS_GEOLOCATION = 'accessGeoLocation',
    ACCESS_LIVESTREAM_DASHBOARD = 'accessLiveStreamDashboard',
    PUBLISH_STORY = 'publishStory',
    UNPUBLISH_STORY = 'unpublishStory',
    EDIT_COMMON_METADATA = 'editCommonMetadata',
    EDIT_MESSAGES = 'editMessages',
    DELETE_MESSAGES = 'deleteMessages',
    ACCESS_TECHNICAL_TAGS = 'accessTechnicalTags',
    FEED_MANAGEMENT = 'feedManagement',
    ACCESS_GALLERY_IMAGE = 'accessGalleryImage',
    ACCESS_NOTIFICATIONS = 'accessNotifications',
    ACCESS_STORY_LOGS = 'accessStoryLogs',
    SUBMIT_STORY = 'submitStory',
    APPROVE_STORY = 'approveStory',
    DECLINE_STORY = 'declineStory',
    CANCEL_SCHEDULED_STORY_PUBLICATION = 'cancelScheduledStoryPublication',
    ACCESS_TAGS_LOGS = 'accessTagsLogs',
}
