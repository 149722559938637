import { gql } from '@apollo/client/core'

export const apNewsroomSearchQuery = gql`
    query apNewsroomSearch($params: APNewsroomSearchParamsInput, $options: APNewsroomSearchOptionsInput) {
        apNewsroomImages(params: $params, options: $options) {
            id
            pagination {
                pageSize
                total
                page
            }
            results {
                itemId
                url
                title
                publishedAt
            }
        }
    }
`

export const apNewsroomImageQuery = gql`
    query apNewsroomImage($id: String!) {
        apNewsroomImage(id: $id) {
            itemId
            cid
            referenceId
            url
            previewUrl
            title
            caption
            provider
            copyright
            credit
            location
            usageTerms
            publishedAt
            createdAt
        }
    }
`
