import { DOCUMENT } from '@angular/common'
import { Inject, Injectable, OnDestroy, Renderer2, RendererFactory2 } from '@angular/core'
import { Apollo } from 'apollo-angular'
import { UserRole } from 'enums/user-role'
import { BehaviorSubject, Observable, Subject } from 'rxjs'
import { debounceTime, pluck, tap } from 'rxjs/operators'
import { AuthService } from 'services/auth/auth'
import { Feature } from '../../interfaces/feature'
import { networkQuery } from '../../tools/apollo'
import { BaseService } from '../base'
import { ErrorsMessagesService } from '../errors-messages/errors-messages.service'
import { feature, features, updateFeatureMutation } from './feature.query'

@Injectable({
    providedIn: 'root',
})
export class FeatureService extends BaseService implements OnDestroy {
    public refresh = new Subject()
    private features: Feature[] = []
    private observer: MutationObserver
    private observerSubject: BehaviorSubject<HTMLElement[]> = new BehaviorSubject([])
    private renderer: Renderer2

    constructor(
        apollo: Apollo,
        protected errorsMessagesService: ErrorsMessagesService,
        private authService: AuthService,
        private rendererFactory: RendererFactory2,
        @Inject(DOCUMENT) private document: Document,
    ) {
        super(apollo, errorsMessagesService)
        this.renderer = this.rendererFactory.createRenderer(null, null)
    }

    public ngOnDestroy() {
        if (this.observerSubject) {
            this.observerSubject.unsubscribe()
        }
        if (this.observer) {
            this.observer.disconnect()
        }
    }

    public async init(): Promise<void> {
        await this.getFeatures()
            .toPromise()
            .then(features => {
                this.features = features
            })

        this.setupFeatureElementsCleaner()
    }

    public isAllowedFeature(name: string): boolean {
        const currentUserSlug = this.authService.currentUser?.slug ?? ''
        const currentUserRoles = this.authService.currentUser?.getRoles() ?? []
        const feature = this.features.find(feature => feature.slug === name)

        if (!feature) return true
        if (!feature.isActive) return false

        const isExcluded = feature.excludedContributors?.includes(currentUserSlug)
        if (isExcluded) return false

        const isDeveloper = currentUserRoles.some(role => role === UserRole.DEVELOPER)
        if (isDeveloper) return true

        const isIncluded = feature.includedContributors?.includes(currentUserSlug)
        if (isIncluded) return true

        const isBetaTester = currentUserRoles.some(role => role === UserRole.BETA_TESTER)
        if (feature.isInBetaTest && isBetaTester) return true

        return feature.isPublic
    }

    public getFeatures(): Observable<Feature[]> {
        return networkQuery<Feature[]>(
            this.apollo,
            {
                query: features,
            },
            ['data', 'features'],
        )
    }

    public getFeature(slug: string): Observable<Feature | undefined> {
        return networkQuery<Feature>(
            this.apollo,
            {
                query: feature,
                variables: {
                    slug,
                },
            },
            ['data', 'feature'],
        )
    }

    public updateFeature(feature: Feature): Observable<Feature> {
        return this.apollo
            .mutate({
                mutation: updateFeatureMutation,
                variables: { feature },
            })
            .pipe(pluck('data', 'updateFeature'))
    }

    private startObserver() {
        this.observer.observe(this.document.body, {
            childList: true,
            subtree: true,
        })
    }

    private setupFeatureElementsCleaner() {
        this.observer = new MutationObserver(() => {
            // Find all relevant elements and push them to the BehaviorSubject
            const elements = Array.from(this.document.querySelectorAll('[href], [feature]')) as HTMLElement[]
            this.observerSubject.next(elements)
        })

        this.observerSubject
            .pipe(
                tap(elements => {
                    // Disconnect observer to avoid infinite loop
                    this.observer.disconnect()
                    this.cleanFeatureElements(elements)
                }),
                debounceTime(1), // Debounce to prevent rapid triggering
                tap(() => {
                    // Reconnect observer
                    this.startObserver()
                }),
            )
            .subscribe()

        this.startObserver()
    }

    private cleanFeatureElements(elements: HTMLElement[]) {
        elements.forEach((element: HTMLElement) => {
            const feature = element.getAttribute('href') || element.getAttribute('feature')

            if (!this.isAllowedFeature(feature!)) {
                this.renderer.removeChild(element.parentElement, element)
            }
        })
    }
}
