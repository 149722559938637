import { MetasImage } from 'models/media.model'

interface TextLinkInterface {
    text: string
    link: string
}

export class InputImageMetadataDto {
    altText: string = ''
    caption: string = ''
    copyright: TextLinkInterface = { text: '', link: '' }
    source: TextLinkInterface = { text: '', link: '' }
    callToAction: TextLinkInterface = { text: '', link: '' }
    langId: number = 1
    textDirection: string = 'ltr'

    constructor(metadata: Partial<Omit<InputImageMetadataDto, 'toMetasImage'>> = {}) {
        Object.assign(this, metadata)
    }

    toMetasImage(): MetasImage {
        return {
            langId: this.langId,
            altText: this.altText,
            caption: this.caption,
            source: this.source,
            copyright: this.copyright,
            callToAction: this.callToAction,
        }
    }
}
