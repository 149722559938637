import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core'
import { takeUntil } from 'rxjs/operators'
import { ContributorFiltersService } from 'services/contributors/contributor-filters.service'
import { BaseAbstractComponent } from '../../base.component'
import {
    ContributorDatagridFiltersInterface,
    ContributorFilterModel,
    ParsedContributorFilterModel,
} from '../datagrid-filterables/filterables.interface'

@Component({
    selector: 'cms-contributor-filters',
    templateUrl: './contributor-filters.component.html',
    styleUrls: ['./contributor-filters.component.scss'],
})
export class ContributorFiltersComponent extends BaseAbstractComponent implements OnInit, OnChanges {
    @Input() datagridFilters: ContributorDatagridFiltersInterface
    @Input() disableAddfilterButton: boolean
    @Output() selectedFilters = new EventEmitter<ContributorDatagridFiltersInterface>()

    @ViewChild('newFilterInput') newFilterInput: ElementRef

    public contributorFilters: ParsedContributorFilterModel[] = []
    public newFilterName: string = ''
    public openFormState: boolean = false
    public activeFilters: ContributorDatagridFiltersInterface = {}
    public activeContributorFilter: number | null
    private loadingDashboardFilters: boolean = false

    constructor(protected contributorFiltersService: ContributorFiltersService) {
        super()
    }

    ngOnInit() {
        this.contributorFiltersService.contributorFilters.pipe(takeUntil(this.ngUnsubscribe)).subscribe(response => {
            this.contributorFilters = this.parseContributorFilters(response)
        })
        this.contributorFiltersService.list()
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.loadingDashboardFilters) {
            this.loadingDashboardFilters = false

            return
        }

        if (
            changes.datagridFilters &&
            JSON.stringify(this.activeFilters) !== JSON.stringify(changes.datagridFilters.currentValue)
        ) {
            this.activeContributorFilter = null
            this.activeFilters = changes.datagridFilters.currentValue
        }
    }

    removeContributorFilter(id: number) {
        this.contributorFiltersService
            .delete(id)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(() => {
                this.contributorFilters = this.contributorFilters.filter(cf => cf.id !== id)
                if (this.activeContributorFilter === id) {
                    this.clearAll()
                }
            })
    }
    activateSelectedFilter(contributorFilter: ParsedContributorFilterModel) {
        this.activeFilters = contributorFilter.filters
        this.activeContributorFilter = contributorFilter.id
        this.loadingDashboardFilters = true
        this.selectedFilters.emit(this.activeFilters)
    }

    removeActiveFilter(filterName: any) {
        delete this.activeFilters[filterName]
        this.activeContributorFilter = null
        this.selectedFilters.emit(this.activeFilters)
    }

    clearAll() {
        this.activeFilters = {}
        this.selectedFilters.emit(this.activeFilters)
        this.activeContributorFilter = null
    }

    dashboardHasFilters(): boolean {
        return Object.keys(this.activeFilters).length > 0
    }

    parseFilters(contributorFilter: ContributorFilterModel): ContributorDatagridFiltersInterface {
        return JSON.parse(contributorFilter.filters)
    }

    parseContributorFilter(contributorFilter: ContributorFilterModel): ParsedContributorFilterModel {
        return {
            ...contributorFilter,
            filters: JSON.parse(contributorFilter.filters),
        }
    }

    parseContributorFilters(contributorFilters: ContributorFilterModel[]): ParsedContributorFilterModel[] {
        return contributorFilters.map(this.parseContributorFilter)
    }

    submit() {
        if (!this.newFilterName) {
            this.closeForm()

            return
        }

        this.contributorFiltersService
            .create(this.newFilterName, JSON.stringify(this.activeFilters))
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(newContributorFilter => {
                this.activeContributorFilter = newContributorFilter.id
                this.contributorFilters.push(this.parseContributorFilter(newContributorFilter))
                this.newFilterName = ''
                this.openFormState = false
            })
    }

    openForm() {
        this.openFormState = true
        this.newFilterInput.nativeElement.focus()
    }

    closeForm() {
        this.openFormState = false
        this.newFilterName = ''
    }
}
