export const featureFragment = `
fragment featureInfo on Feature {
    id
    slug
    title
    description
    isActive
    isPublic
    isInBetaTest
    includedContributors
    excludedContributors
}
`
