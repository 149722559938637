import { Injectable, OnDestroy } from '@angular/core'
import { Apollo } from 'apollo-angular'
import { Subject } from 'rxjs'
import { ErrorsMessagesService } from './errors-messages/errors-messages.service'

@Injectable()
export class BaseService implements OnDestroy {
    protected apollo: Apollo
    protected ngUnsubscribe: Subject<void> = new Subject()

    constructor(apollo: Apollo, protected errorMessagesServices: ErrorsMessagesService) {
        this.apollo = apollo
    }

    public ngOnDestroy(): void {
        this.ngUnsubscribe.next()
        this.ngUnsubscribe.complete()
    }
}
