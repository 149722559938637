export enum PyramidDesk {
    AFRICANEWS_ENGLISH = 16,
    AFRICANEWS_FRENCH = 17,
    ARABIC = 18,
    BUSINESS = 5,
    CULTURE = 8,
    DIGITAL_NEWS = 3,
    ENGLISH = 19,
    EUROVIEWS = 10,
    EU_MAGAZINES = 12,
    EU_NEWS = 11,
    EU_PROGRAMS = 2,
    FARSI = 20,
    FRENCH = 21,
    GERMAN = 22,
    GREEN = 4,
    GREEK = 23,
    HEADLINES = 1,
    HEALTH = 7,
    HUNGARIAN = 24,
    ITALIAN = 25,
    NEXT = 9,
    NO_COMMENT = 15,
    POLISH = 30,
    PORTUGUESE = 26,
    RUSSIAN = 27,
    SOCIAL_MEDIA = 14,
    SPANISH = 28,
    SPONSOR_MAGAZINES = 13,
    TRAVEL = 6,
    TURKISH = 29,
}

export enum PyramidDeskLabel {
    AFRICANEWS_ENGLISH = 'Africanews English',
    AFRICANEWS_FRENCH = 'Africanews French',
    ARABIC = 'Arabic',
    BUSINESS = 'Business',
    CULTURE = 'Culture',
    DIGITAL_NEWS = 'Digital News',
    ENGLISH = 'English',
    EUROVIEWS = 'Euroviews',
    EU_MAGAZINES = 'EU Magazines',
    EU_NEWS = 'EU News',
    EU_PROGRAMS = 'EU Programs',
    FARSI = 'Farsi',
    FRENCH = 'French',
    GERMAN = 'German',
    GREEN = 'Green',
    GREEK = 'Greek',
    HEADLINES = 'Headlines',
    HEALTH = 'Health',
    HUNGARIAN = 'Hungarian',
    ITALIAN = 'Italian',
    NEXT = 'Next',
    NO_COMMENT = 'No Comment',
    POLISH = 'Polish',
    PORTUGUESE = 'Portuguese',
    RUSSIAN = 'Russian',
    SOCIAL_MEDIA = 'Social Media',
    SPANISH = 'Spanish',
    SPONSOR_MAGAZINES = 'Sponsor Magazines',
    TRAVEL = 'Travel',
    TURKISH = 'Turkish',
}
