import { Injectable } from '@angular/core'

export interface ImageResizeOptions {
    maxWidth: number
    maxHeight: number
    maxFileSize: number
}

@Injectable()
export class ImageResizeService {
    public shouldResizeImage(image: File & { width: number; height: number }, options: ImageResizeOptions) {
        return image.width > options.maxWidth || image.height > options.maxHeight || image.size > options.maxFileSize!
    }

    public async resizeImage(file: File, options: ImageResizeOptions): Promise<File> {
        return new Promise((resolve, reject) => {
            const image = new Image()
            const reader = new FileReader()

            const cleanup = () => {
                image.onload = null
                reader.onload = null
                reader.onerror = null
            }

            const handleReaderLoad = () => {
                image.src = reader.result as string
            }

            const handleReaderError = (error: ProgressEvent<FileReader>) => {
                cleanup()
                reject(error)
            }

            const handleImageLoad = () => {
                const canvas = document.createElement('canvas')
                let width = image.width
                let height = image.height

                // Select canvas size, keeping aspect ratio
                if (width > options.maxWidth || height > options.maxHeight) {
                    if (width > height) {
                        height = (height * options.maxWidth) / width
                        width = options.maxWidth
                    } else {
                        width = (width * options.maxHeight) / height
                        height = options.maxHeight
                    }
                }

                // Draw image on canvas
                canvas.width = width
                canvas.height = height
                const ctx = canvas.getContext('2d')!
                ctx.drawImage(image, 0, 0, width, height)

                const shouldCompress =
                    file.size > options.maxFileSize &&
                    image.width <= options.maxWidth &&
                    image.height <= options.maxHeight

                // Convert canvas to a Blob and then to a File
                canvas.toBlob(
                    blob => {
                        cleanup()
                        if (blob) {
                            const resizedFile = new File([blob], file.name, { type: 'image/jpeg' })
                            resolve(resizedFile)
                        } else {
                            reject(new Error('Canvas is empty'))
                        }
                    },
                    'image/jpeg',
                    shouldCompress ? 0.98 : 1,
                )
            }

            reader.onload = handleReaderLoad
            reader.onerror = handleReaderError
            image.onload = handleImageLoad

            reader.readAsDataURL(file)
        })
    }
}
