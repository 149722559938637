import gql from 'graphql-tag'
import { featureFragment } from './feature.fragment'

export const features = gql`
    ${featureFragment}

    query features {
        features {
            ...featureInfo
        }
    }
`

export const feature = gql`
    ${featureFragment}

    query feature($slug: String!) {
        feature(slug: $slug) {
            ...featureInfo
        }
    }
`

export const updateFeatureMutation = gql`
    ${featureFragment}

    mutation updateFeature($feature: FeatureInput!) {
        updateFeature(feature: $feature) {
            ...featureInfo
        }
    }
`
