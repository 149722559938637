import { Component, Input } from '@angular/core'

@Component({
    selector: 'cms-char-counter',
    templateUrl: './char-counter.component.html',
    styleUrls: ['./char-counter.component.scss'],
})
export class CharCounterComponent {
    protected _value: string = ''

    @Input() maxLength: number = 160
    @Input() minLength: number = 0

    /**
     * Recommended length is used to calculate the warning colour in char count see CharCounterComponent.hasWarning()
     * It's used too for the recommend text on right side if no recommendString is set
     *
     * Recommended: {{ recommendedString ||recommendedLength }} chars
     */
    @Input() recommendedLength?: number

    /**
     * String to use in the recommend text on the right side
     *
     * Recommended: {{ recommendedString ||recommendedLength }} chars
     */
    @Input() recommendedString: string

    @Input('value')
    set value(value: string) {
        if (!value) {
            value = ''
        }

        this._value = value
    }

    get value() {
        return this._value
    }

    getStatusClass(): string {
        if (this.value.length > this.maxLength || this.value.length < this.minLength) {
            return 'is-error'
        }

        const warningCondition = this.recommendedLength
            ? this.value.length > this.recommendedLength
            : this.maxLength - this.value.length < Math.floor(this.maxLength / 10)
        if (warningCondition) {
            return 'is-warning'
        }

        return 'is-success'
    }
}
