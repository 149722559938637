import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { APP_INITIALIZER, NgModule } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations'
import { RouterModule } from '@angular/router'
import {
    OWL_DATE_TIME_LOCALE,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
} from '@danielmoncada/angular-datetime-picker'
import { AuthMock } from 'mocks/services/auth-mock'
import { NgxClarityModule } from 'ngx-clarity'
import { SocketIoModule } from 'ngx-socket-io'
import { ContributorGetNamePipe } from 'pipes/contributor'
import { FormatDatePipe } from 'pipes/format-date.pipe'
import { PublicationDatePipe } from 'pipes/publication-date.pipe'
import { TranslationFilterPipe } from 'pipes/translation-filter.pipe'
import { AuthService } from 'services/auth/auth'
import { DashboardConstantsService } from 'services/dashboard/dashboard-constants.service'
import { DashboardFiltersListService } from 'services/dashboard/filters-list/dashboard-filters-list.service'
import { StoriesService } from 'services/dashboard/stories-service'
import { FeatureService } from 'services/feature/feature.service'
import { LanguagesService } from 'services/languages/languages'
import { socketIoConfig } from 'services/notifications/clients/socket-io-client.service'
import { ContributorsNotificationService } from 'services/notifications/contributors-notification.service'
import { SurveyLinkService } from 'services/survey-link/survey-link.service'
import { environment } from '../environments/environment'
import { AuthGuard } from '../guards/auth'
import { BadCookieInterceptor } from '../http-interceptor/bad-cookie.interceptor'
import { EventsService } from '../services/dashboard/events-service'
import { StoriesByEdition } from '../services/dashboard/stories-by-edition'
import { EventEnglishGuideService } from '../services/event-english-guide/event-english-guide'
import { EventService } from '../services/events/event'
import { ProgramDeliverableListService } from '../services/program-deliverables/list'
import { ProgramListService } from '../services/programs/list'
import { AdvancedStorySearchService } from '../services/story/advanced-search/query'
import { VerticalService } from '../services/verticals/verticals.service'
import { AppComponent } from './app.component'
import { ROUTES } from './app.routes'
import { DashboardEventsComponent } from './dashboard/dashboard-events/dashboard-events.component'
import { DashboardStoriesDetailComponent } from './dashboard/dashboard-stories/dashboard-stories-detail/dashboard-stories-detail.component'
import { DashboardStoriesComponent } from './dashboard/dashboard-stories/dashboard-stories.component'
import { DashboardComponent } from './dashboard/dashboard.component'
import { EditEventComponent } from './dashboard/edit-event/edit-event.component'
import { AssignContributorComponent } from './dashboard-galaxy/assign-contributor/assign-contributor.component'
import { CreateEventComponent } from './dashboard-galaxy/create-event/create-event.component'
import { DashboardGalaxyEditionDetailComponent } from './dashboard-galaxy/dashboard-galaxy-edition/dashboard-galaxy-edition-detail/dashboard-galaxy-edition-detail.component'
import { DashboardGalaxyEditionComponent } from './dashboard-galaxy/dashboard-galaxy-edition/dashboard-galaxy-edition.component'
import { DashboardGalaxyComponent } from './dashboard-galaxy/dashboard-galaxy.component'
import { LoginComponent } from './login/login.component'
import { MenuComponent } from './menu/menu.component'
import { HeaderSearchResultsComponent } from './shared/header-search/header-search-results/header-search-results.component'
import { HeaderSearchComponent } from './shared/header-search/header-search.component'
import { SharedModule } from './shared/shared.module'
import { TodayDateComponent } from './shared/today-date/today-date.component'
import { UnauthorizedComponent } from './unauthorized/unauthorized.component'

@NgModule({
    declarations: [
        AppComponent,
        MenuComponent,
        DashboardComponent,
        DashboardEventsComponent,
        DashboardStoriesComponent,
        EditEventComponent,
        DashboardGalaxyComponent,
        LoginComponent,
        DashboardGalaxyEditionComponent,
        TodayDateComponent,
        HeaderSearchComponent,
        HeaderSearchResultsComponent,
        CreateEventComponent,
        DashboardGalaxyEditionDetailComponent,
        AssignContributorComponent,
        UnauthorizedComponent,
        DashboardStoriesDetailComponent,
    ],
    imports: [
        ReactiveFormsModule,
        SharedModule,
        BrowserModule,
        BrowserAnimationsModule,
        NoopAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(ROUTES, { relativeLinkResolution: 'legacy' }),
        NgxClarityModule.forRoot({
            enabled: environment.production,
            projectId: environment.clarityProjectId,
        }),
        SocketIoModule.forRoot(socketIoConfig),
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
    ],
    providers: [
        LanguagesService,
        {
            provide: APP_INITIALIZER,
            useFactory: (languagesService: LanguagesService) => () => languagesService.init(),
            deps: [LanguagesService],
            multi: true,
        },
        FeatureService,
        {
            provide: APP_INITIALIZER,
            useFactory: (featureService: FeatureService) => () => featureService.init(),
            deps: [FeatureService],
            multi: true,
        },
        {
            provide: AuthService,
            useClass: environment.production || !environment.useStoryMock ? AuthService : AuthMock,
        },
        AdvancedStorySearchService,
        EventService,
        EventEnglishGuideService,
        AuthGuard,
        EventsService,
        StoriesByEdition,
        StoriesService,
        ProgramListService,
        VerticalService,
        ProgramDeliverableListService,
        DashboardFiltersListService,
        DashboardConstantsService,
        ContributorGetNamePipe,
        FormatDatePipe,
        PublicationDatePipe,
        TranslationFilterPipe,
        ContributorsNotificationService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: BadCookieInterceptor,
            multi: true,
        },
        { provide: OWL_DATE_TIME_LOCALE, useValue: 'en-GB' },
        SurveyLinkService,
    ],
    exports: [BrowserModule, HttpClientModule],
    bootstrap: [AppComponent],
    schemas: [],
})
export class AppModule {}
