import { Injectable } from '@angular/core'
import { Apollo } from 'apollo-angular'
import { Observable } from 'rxjs'
import { cacheAndNetworkQuery } from 'tools/apollo'
import { imageSearchQuery } from './image-search.queries'

@Injectable()
export class ImageSearchService {
    /**
     *
     * @param api
     */
    constructor(protected apollo: Apollo) {}

    /**
     * F..Y.I. Not using a BehaviourSubject to store results as we don't want to share them across components
     *
     * @param searchterm
     * @returns {Observable<any>}
     */
    search(searchTerm: string, minWidth?: number): Observable<any> {
        return cacheAndNetworkQuery(
            this.apollo,
            {
                query: imageSearchQuery,
                variables: {
                    search: {
                        query: searchTerm,
                        minWidth,
                    },
                },
            },
            ['data', 'images'],
        )
    }
}
